@use 'sass:math';
@use '../_mixin' as m;
/*----------
	side nav
----------*/
$_root: '.l-sidenav';

// サイドナビ
.l-sidenav {
	position: relative;
	z-index: 1;
	margin-bottom: 50px;

	&__items {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		width: calc(50% - 1px);
		margin-bottom: 1px;
		background-color: #ffffff;
		border-top: solid 1px var(--color-prim);
		line-height: var(--line-height-l);

		&:nth-child(odd) {
			margin-right: 1px;
		}

		&:nth-child(even) {
			margin-left: 1px;
		}
	}

	&__item-inner {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;
		min-height: 64px;
		padding: 19px 40px 19px 20px;
		font-size: 1.4rem;
		color: var(--color-txt);

		&::before {
			@include m.iconfont-default;
			content: m.icon('arrow2-b');
			position: absolute;
			right: 20px;
			top: 50%;
			display: block;
			width: 14px;
			height: 14px;
			font-size: 1.4rem;
			font-weight: 500;
			line-height: 14px;
			transform: translateY(-50%);
		}
	}

	@include m.mq-pc {
		position: sticky;
		top: 80px;
		margin-bottom: 0;

		&__items {
			display: block;
			border-top: solid 1px var(--color-prim);
		}

		&__item {
			width: 100%;
			margin-bottom: 0;
			border-top: none;
			border-bottom: solid 1px var(--color-border);

			&:nth-child(odd) {
				margin-right: 0;
			}

			&:nth-child(even) {
				margin-left: 0;
			}
		}

		&__item-inner {
			min-height: 84px;
			padding: 28px 45px 28px 16px;
			font-size: 1.6rem;

			&::before {
				right: 15px;
				width: 16px;
				height: 16px;
				font-size: 1.6rem;
				line-height: 16px;
			}

			&[href] {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}