@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main content
----------*/

// コンテンツ全体枠
.l-content {
	width: 100%;
	position: relative;
	// overflow-x: hidden;
	padding-top: 56px;

	@include m.mq-pc {
		min-width: 1240px;
		padding-top: 80px;
	}
}

// メインコンテンツ
.l-structure {
	.l-structure__main {
		margin-bottom: 100px;
	}

	// サイドナビあり
	&--double {
		width: 100%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 20px;
		padding-right: 20px;

		.l-structure__side {
			position: relative;
			margin-left: -20px;
			margin-right: -20px;
			background-color: var(--color-bg);

			&::before {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				display: block;
				width: 50vw;
				height: 100%;
				background-color: var(--color-bg);
			}
		}
	}

	@include m.mq-pc {
		.l-structure__main {
			margin-bottom: 150px;
		}

		// サイドナビあり
		&--double {
			display: flex;
			padding-right: 0;
			padding-left: 0;

			.l-structure__main {
				flex: 1;
			}

			.l-structure__side {
				width: 230px;
				margin: 0 70px 0 0;
			}
		}
	}

	@include m.mq-print {
		.l-structure__side {
			display: none;
		}
	}
}

// セクション枠 最大幅設定・中央配置
.l-section {
	@include m.inner-content;

	&:first-child > *:first-child {
		margin-top: 0;
	}

	// 左右のpaddingなし
	&--gapless {
		padding-left: 0;
		padding-right: 0;
	}

	// 横1200pxのsection
	&--1200 {
		max-width: 1240px;
	}

	@include m.mq-print {
		max-width: none;
	}
}

// main
.l-main {
	width: 100%;
	overflow: hidden;
}

// ヘッダータイトルエリア
.l-title-container {
	$this: &;
	margin-bottom: 50px;
	padding: 55px 0 20px;
	border-bottom: solid 1px #D7D8DE;

	&__inner {
		@include m.inner-content(1200px);
	}

	&__breadcrumb {
		margin-bottom: 35px;
	}

	// h1タイトルエリアに画像あり
	&--visual {
		margin-bottom: 73px;
		padding-bottom: 0;
		border-bottom: 0;

		#{$this}__visual {
			position: relative;
			width: auto;
			height: 128px;
			margin: 25px -20px 0;

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: calc(100% - 20px);
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			&::before {
				content: '';
				position: absolute;
				right: 20px;
				bottom: -33px;
				z-index: 1;
				display: block;
				width: 100px;
				height: 100px;
				background: url(/assets/images/bg_shape.svg) no-repeat 0 0 / cover;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				display: block;
				width: 124px;
				height: 128px;
				background: url(/assets/images/bg_single_slash.svg) no-repeat 0 0 / auto 128px;
			}
		}
	}

	@include m.mq-pc {
		margin-bottom: 80px;
		padding: 60px 0 30px;

		&__breadcrumb {
			margin-bottom: 28px;
		}

		&--visual {
			margin-bottom: 128px;
			padding-bottom: 0;
			border-bottom: 0;

			#{$this}__visual {
				width: 100%;
				height: 300px;
				margin: 30px 0 0;

				img {
					left: calc(-1 * (100vw - 1200px) / 2);
					@media (max-width: 1239px) {
						left: -20px;
					}
					top: auto;
					width: calc(100vw - 120px);
					@media (max-width: 1439px) {
						width: calc(1200px + ((100vw - 1200px) / 2));
					}
					min-width: 1220px;
				}

				&::before {
					right: 0;
					bottom: -72px;
					width: 216px;
					height: 216px;
				}

				&::after {
					left: -120px;
					width: 300px;
					height: 300px;
					background: url(/assets/images/bg_single_slash.svg) no-repeat 0 0 / auto 300px;
				}
			}
		}
	}
}

// フッター上 コンタクトエリア
.l-contact-area {
	$this: &;
	width: 100%;
	position: relative;
	overflow-x: hidden;
	background: url(/assets/images/bg_contact_sp.png) no-repeat center center / cover;

	&__inner {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 350px;
	}

	&__item {
		position: relative;
		flex: 1;
		text-align: center;

		&:nth-child(2)::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			opacity: .28;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #ffffff;
		}
	}

	&__item-link {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-bottom: 10px;
		color: #ffffff;
		line-height: var(--line-height-m);
	}

	&__icon {
		width: 35px;
		height: 35px;

		&.icon-tel2 {
			font-size: 3.15rem;
		}

		&.icon-mail {
			font-size: 3.5rem;
		}
	}

	&__title {
		font-size: 1.6rem;
		font-weight: bold;
	}

	&__text {
		margin-top: 10px;
		font-size: 1.2rem;
	}

	&__tel {
		margin-top: 3px;
		font-family: Inter, var(--font-sans);
		font-size: 1.6rem;
		font-weight: bold;
		color: #ffffff;
	}

	&__icon-link {
		position: absolute;
		right: 14px;
		bottom: 14px;
		display: block;
		width: 28px;
		height: 28px;
		border: solid 1px #ffffff;
		color: #ffffff;
		text-align: center;

		&::before {
			font-size: 1rem;
			line-height: 26px;
		}

		#{$this} a[target=_blank] &::before {
			content: m.icon('blank');
		}
	}

	// ニュース
	&--news {
		background: url(/assets/images/bg_contact_news_sp.png) no-repeat center center / cover;

		#{$this}__inner {
			height: 266px;
		}

		#{$this}__item {
			&::before {
				display: none;
			}
		}

		#{$this}__item-link {
			padding-bottom: 0;
		}
	}

	@include m.mq-pc {
		min-width: 1240px;
		background: url(/assets/images/bg_contact.png) no-repeat center center / cover;

		&__inner {
			height: 400px;
		}

		&__item-link {
			padding-bottom: 0;

			&[href] {
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					opacity: 0;
					display: block;
					width: 100%;
					height: 100%;
					background-color: #000000;
					transition: opacity .2s;
				}

				&:hover {
					&::before {
						opacity: .6;
					}
				}
			}
		}

		&__icon {
			position: relative;
			z-index: 1;
			width: 42px;
			height: 42px;

			&.icon-tel2 {
				font-size: 4.2rem;
			}

			&.icon-mail {
				font-size: 4.2rem;
			}
		}

		&__title {
			position: relative;
			z-index: 1;
			margin-top: 6px;
			font-size: 2rem;
		}

		&__text {
			position: relative;
			z-index: 1;
			font-size: 1.4rem;
		}

		&__tel {
			position: relative;
			z-index: 1;
			font-size: 2.4rem;
		}

		&__icon-link {
			right: 20px;
			bottom: 20px;
			width: 32px;
			height: 32px;

			&::before {
				font-size: 1.2rem;
				line-height: 30px;
			}
		}

		// ニュース
		&--news {
			background: url(/assets/images/bg_contact_news.png) no-repeat center center / cover;

			#{$this}__inner {
				height: 284px;
			}

			#{$this}__item-link {
				position: relative;
				max-width: 720px;
				margin: 0 auto;
				padding-bottom: 0;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					opacity: .28;
					display: block;
					width: 1px;
					height: 100%;
					background-color: #ffffff;
				}

				&::after {
					content: '';
					position: absolute;
					right: 0;
					top: 0;
					opacity: .28;
					display: block;
					width: 1px;
					height: 100%;
					background-color: #ffffff;
				}
			}
		}
	}

	@include m.mq-print {
		display: none;
	}
}

// トップへ戻るボタン
.l-backhead {
	visibility: hidden;
	opacity: 0;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 10;
	width: 56px;
	height: 56px;
	background-color: #A8A8A8;
	transition: opacity .2s, visibility .2s;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 56px;
		height: 56px;
		color: #ffffff;
		transition: background-color .2s, color .2s;
	}

	i {
		font-size: 1.6rem;
	}

	&.is-visible {
		visibility: visible;
		opacity: 1;
	}

	@include m.mq-pc {
		width: 60px;
		height: 60px;

		a {
			width: 60px;
			height: 60px;

			&:hover {
				background-color: var(--color-txt);
				color: #ffffff;
			}
		}
	}
}