@use '_mixin' as m;
// ----------
// Utility
// ----------

/*----------
	margin
----------*/
// stylelint-disable declaration-no-important

/* 固定マージン */
.u-mt-none {
	margin-top: 0 !important;
}

.u-mb-none {
	margin-bottom: 0 !important;
}

/* 汎用マージン */
.u-margin-xs {
	margin-bottom: 10px!important;
	@include m.mq-pc {
		margin-bottom: 20px!important;
	}
}

.u-margin-sm {
	margin-bottom: 20px!important;
	@include m.mq-pc {
		margin-bottom: 30px!important;
	}
}

.u-margin-md {
	margin-bottom: 24px!important;
	@include m.mq-pc {
		margin-bottom: 40px!important;
	}
}

.u-margin-lg {
	margin-bottom: 40px!important;
	@include m.mq-pc {
		margin-bottom: 60px!important;
	}
}

.u-margin-xl {
	margin-bottom: 50px!important;
	@include m.mq-pc {
		margin-bottom: 80px!important;
	}
}

.u-margin-xxl {
	margin-bottom: 60px!important;
	@include m.mq-pc {
		margin-bottom: 100px!important;
	}
}

.u-margin-xxxl {
	margin-bottom: 80px!important;
	@include m.mq-pc {
		margin-bottom: 120px!important;
	}
}

.u-margin-xxxxl {
	margin-bottom: 100px!important;
	@include m.mq-pc {
		margin-bottom: 150px!important;
	}
}

.u-margin-none {
	margin-bottom: 0!important;
	@include m.mq-pc {
		margin-bottom: 0!important;
	}
}

/* パーツ用マージン */
@include m.mq-pc {
	@for $i from 1 through 20 {
		.u-pc-mt-#{$i * 5} {
			margin-top: #{$i * 5}px !important;
		}
		.u-pc-mb-#{$i * 5} {
			margin-bottom: #{$i * 5}px !important;
		}
		.u-pc-ml-#{$i * 5} {
			margin-left: #{$i * 5}px !important;
		}
		.u-pc-mr-#{$i * 5} {
			margin-right: #{$i * 5}px !important;
		}
	}
}

@include m.mq-sp {
	@for $i from 1 through 20 {
		.u-sp-mt-#{$i * 5} {
			margin-top: #{$i * 5}px !important;
		}
		.u-sp-mb-#{$i * 5} {
			margin-bottom: #{$i * 5}px !important;
		}
		.u-sp-ml-#{$i * 5} {
			margin-left: #{$i * 5}px !important;
		}
		.u-sp-mr-#{$i * 5} {
			margin-right: #{$i * 5}px !important;
		}
	}
}


/*----------
	display
----------*/
.u-pc-v {
	@include m.mq-sp {
		display: none;
	}
}

.u-sp-v {
	@include m.mq-pc {
		display: none;
	}
}


/*----------
	text
----------*/
.u-pcbr {
	@include m.mq-sp {
		display: none;
	}
}

.u-spbr {
	@include m.mq-pc {
		display: none;
	}
}

.u-txt-number {
	font-family: Inter, var(--font-sans);
}

.u-txt-fz10 {
	font-size: 1rem !important;
}

.u-txt-fz12 {
	font-size: 1.2rem !important;
}

.u-txt-fz13 {
	font-size: 1.3rem !important;
}

.u-txt-fz14 {
	font-size: 1.4rem !important;
}

.u-txt-fz16 {
	font-size: 1.6rem !important;
}

.u-txt-fz18 {
	font-size: 1.8rem !important;
}

.u-txt-fz20 {
	font-size: 2rem !important;
}

.u-txt-fz24 {
	font-size: 2.4rem !important;
}

.u-txt-center {
	text-align: center !important;
}

.u-txt-pccenter {
	@include m.mq-pc {
		text-align: center !important;
	}
}

.u-txt-right {
	text-align: right !important;
}

.u-txt-left {
	text-align: left !important;
}

.u-txt-normal {
	font-weight: normal !important;
}

.u-txt-medium {
	font-weight: 500 !important;
}

.u-txt-bold {
	font-weight: bold !important;
}

.u-txt-strike {
	text-decoration: line-through !important;
}

.u-text-indent {
	text-indent: -1em;
	padding-left: 1em;
}

.u-text-indent_1_5em {
	text-indent: -1.5em;
	padding-left: 1.5em;
}

.u-text-indent_2_5em {
	text-indent: -2.5em;
	padding-left: 2.4em;
}


.u-txt-marker {
	font-weight: bold;
	background-image: linear-gradient(transparent 80%, #FFF7A6 80%);
}

.u-txt-default {
	color: var(--color-txt) !important;
}

.u-txt-white {
	color: #fff !important;
}

.u-txt-caution {
	color: var(--color-caution) !important;
}

/* 文中リンク */
.u-txt-link {
	text-decoration: underline;
	color: var(--color-txt);
	@include m.mq-pc {
		transition: opacity .2s;

		&:hover {
			opacity: .7;
		}
	}
}

/* 文字サイズ */
@include m.mq-pc {
	@for $i from 10 through 32 {
		.u-pc-fz-#{$i} {
			font-size: #{$i * .1}rem !important;
		}
	}
}
@include m.mq-sp {
	@for $i from 10 through 32 {
		.u-sp-fz-#{$i} {
			font-size: #{$i * .1}rem !important;
		}
	}
}
// stylelint-enable declaration-no-important

/*----------
	image
----------*/
.u-img-auto {
	width: auto;
	height: auto;
}