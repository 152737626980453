@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main footer
----------*/
$_root: '.l-mainfooter';

footer {
	width: 100%;
	position: relative;
	overflow-x: hidden;

	@include m.mq-pc {
		min-width: 1240px;
	}

	@include m.mq-print {
		display: none;
	}
}

// stylelint-disable no-descending-specificity
.l-mainfooter {
	position: relative;
	border-top: solid 1px #D7D8DE;

	&::before {
		content: '';
		position: absolute;
		right: 0;
		top: -1px;
		z-index: 1;
		display: block;
		width: 108px;
		height: 72px;
		background: url(/assets/images/bg_shape.svg) no-repeat left bottom / 108px auto;
	}

	&__inner {
		padding: 58px 20px 24px;
	}

	&__logo {
		display: none;
	}

	&__nav {
		margin-bottom: 17px;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 2;

		a {
			display: inline-block;

			&::after {
				@include m.iconfont-default;
				display: none;
				width: 1em;
				height: 1em;
				margin-left: 8px;
			}

			// 別窓のアイコン
			&[target='_blank']::after {
				display: inline-block;
				content: m.icon('blank');
			}

			// PDFのアイコン
			&[href$='.pdf']::after {
				display: inline-block;
				content: m.icon('pdf');
			}
		}

		&-section {
			> li {
				> a {
					padding: 8px 0;
				}
			}
		}

		&-toggle {
			> a {
				position: relative;

				&::after {
					@include m.iconfont-default;
					content: m.icon('plus');
					width: 1.2rem;
					height: 1.2rem;
					display: inline-block;
					margin-left: 8px;
					font-size: 1.2rem;
					transition: transform .3s ease;
					transform: rotate(-90deg);
				}
			}

			&.is-toggle-open > a::after {
				content: m.icon('minus');
				transform: rotate(0deg);
			}
		}

		&-lv3 {
			visibility: hidden;
			opacity: 0;
			height: 0;
			padding-bottom: 8px;
			transition: opacity 0s, visibility 0s;

			.is-toggle-open & {
				visibility: visible;
				opacity: 1;
				height: auto;
				transition: opacity .6s ease-out, visibility .6s;
			}

			> li {
				margin-left: 1em;
				font-size: 1.2rem;

				> a {
					padding: 2px 0;
				}
			}
		}

		&-lv4 {
			padding-bottom: 2px;

			> li {
				margin-left: 2em;
				font-weight: 500;

				> a {
					padding: 1px 0;
				}
			}
		}
	}

	&__nav-sub {
		margin-bottom: 40px;
		font-size: 1.2rem;
		line-height: 2;

		li {
			a {
				display: inline-block;
				padding: 3px 0;
				color: #7E7E7E;

				&::after {
					@include m.iconfont-default;
					width: 1.4rem;
					height: 1.4rem;
					display: none;
					margin-left: 3px;
					font-size: 1.4rem;
				}

				&[target='_blank']::after {
					content: m.icon('blank');
					display: inline-block;
				}

				&[href$='.pdf']::after {
					content: m.icon('pdf');
					display: inline-block;
				}
			}
		}
	}

	&__copy {
		font-size: 1.2rem;
		color: #868686;
	}

	@include m.mq-pc {
		&::before {
			right: calc(50% + 504px);
			top: auto;
			bottom: 0;
			width: 216px;
			height: 144px;
			background: url(/assets/images/bg_shape.svg) no-repeat left top / 216px auto;
		}

		&__inner {
			@include m.inner-content;
			padding-top: 50px;
			padding-bottom: 45px;
		}

		&__logo {
			display: block;
			width: 80px;
			min-height: 74px;
			margin: 0 auto 46px;
		}

		&__nav {
			display: flex;
			justify-content: space-between;
			margin-bottom: 46px;
			font-size: 1.6rem;
			line-height: 1.75;

			a {
				&::after {
					width: 1.4em;
					height: 1.4em;
					font-size: 1.4rem;
				}
			}

			&-section {
				width: calc(33.33% - 26px);

				> li {
					&:not(:first-child) {
						margin-top: 36px;
					}

					> a {
						display: block;
						padding: 0 0 3px;
						border-bottom: solid 1px #d2d2d2;
						transition: border-color .2s;

						&:hover {
							border-color: var(--color-prim);
						}
					}
				}
			}

			&-toggle {
				> a {
					&::after {
						display: none;
					}
				}
			}

			&-lv3 {
				visibility: visible;
				opacity: 1;
				height: auto;
				margin-top: 22px;
				padding-bottom: 0;
				transition: none;

				> li {
					margin-left: 0;
					font-size: 1.4rem;

					&:not(:first-child) {
						margin-top: 14px;
					}

					> a {
						padding: 0;
						transition: box-shadow .2s;

						&:hover {
							box-shadow: 0 1px 0 0 var(--color-txt);
						}
					}
				}
			}

			&-lv4 {
				margin-top: 12px;
				padding-bottom: 0;

				> li {
					margin-left: 1em;
					font-size: 1.2rem;

					&:not(:first-child) {
						margin-top: 5px;
					}

					> a {
						padding: 0;
						transition: box-shadow .2s;

						&:hover {
							box-shadow: 0 1px 0 0 var(--color-txt);
						}
					}
				}
			}
		}

		&__nav-sub {
			margin-bottom: 128px;

			ul {
				display: flex;
				justify-content: center;
				line-height: var(--line-height-m);
			}

			li {
				position: relative;

				&:not(:last-child) {
					padding-right: 21px;

					&::before {
						content: '';
						position: absolute;
						right: 10px;
						top: 2px;
						display: block;
						width: 1px;
						height: 17px;
						background-color: #CCCCCC;
					}
				}

				a {
					padding: 0;
					transition: box-shadow .2s;

					&:hover {
						box-shadow: 0 1px 0 0 #7E7E7E;
					}
				}
			}
		}

		&__copy {
			text-align: center;
		}
	}
}
// stylelint-enable no-descending-specificity