@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main header
----------*/
$_root: '.l-mainheader';

header {
	width: 100%;
	position: relative;
	overflow-x: hidden;

	@include m.mq-pc {
		min-width: 1240px;
	}

	@include m.mq-print {
		display: none;
	}
}

.l-mainheader {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 56px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #ffffff;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 56px;
		background-color: #ffffff;
		z-index: 110;
	}

	a {
		display: block;
		color: var(--color-txt);
	}

	&__logo {
		position: relative;
		z-index: 110;
		width: 122px;
		padding: 18px 0 0 20px;

		img {
			width: 100%;
		}
	}

	&__menu-btn {
		position: relative;
		z-index: 110;
		display: block;
		width: 56px;
		height: 56px;
		cursor: pointer;
		background-color: var(--color-prim);

		&::before,
		&::after,
		> span {
			content: '';
			position: absolute;
			top: 0;
			left: 16px;
			display: block;
			width: 24px;
			height: 2px;
			background-color: #ffffff;
			transition: transform .2s, opacity .1s;
		}

		&::before {
			top: 20px;
		}

		&::after {
			top: 34px;
		}

		> span {
			top: 27px;
		}

		&.is-open {
			&::before {
				transform: translateY(7px) rotate(45deg);
			}

			&::after {
				transform: translateY(-7px) rotate(-45deg);
			}

			> span {
				opacity: 0;
			}
		}
	}

	@include m.mq-pc {
		min-width: 1240px;
		height: 80px;

		&::before {
			display: none;
		}

		&__logo {
			position: static;
			width: 190px;
			padding: 26px 0 0 40px;
		}

		&__menu-btn {
			display: none;
		}
	}
}
