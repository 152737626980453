@use 'sass:math';
@use '../_mixin' as m;
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&family=Noto+Serif+JP:wght@400&family=Inter:wght@400;600;700;800&display=swap);

/*----------
	base
----------*/

html {
	width: 100%;
	font-size: 62.5%;
	background-color: #fff;
	scroll-padding-top: 80px;

	&.is-locked {
		position: absolute;
		overflow: hidden;
	}

	@include m.mq-sp {
		scroll-padding-top: 56px;
	}
}

body {
	font-size: 1.6rem;
	line-height: var(--line-height-l);
	font-family: var(--font-sans);
	letter-spacing: 0;
	color: var(--color-txt);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	@include m.mq-sp {
		font-size: 1.4rem;
	}

	@include m.mq-print {
		min-width: 1240px;
		zoom: .8;
	}
}

a {
	text-decoration: none;
	color: var(--color-txt);

	@include m.mq-pc {
		&:hover {
			text-decoration: none;
		}
	}
}

img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

sup {
	vertical-align: top;
	font-size: .7em;
}

sub {
	vertical-align: baseline;
	font-size: .7em;
}

input[type='text'],
textarea,
select {
	font-family: inherit;
	font-size: 1.6rem /* SP時16px以下にしない */;
}
