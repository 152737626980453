@use 'sass:math';
@use '../_mixin' as m;
/*----------
	main nav
----------*/
$_root: '.l-mainnav';

// stylelint-disable no-descending-specificity
.l-mainnav {
	display: none;

	@include m.mq-sp {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
		display: block;
		width: 100%;
		height: 0;
		overflow-y: auto;
		scroll-behavior: smooth;
		padding-top: 56px;
		visibility: hidden;
		opacity: 0;
		transition: opacity .2s, visibility .2s;

		&.is-hamburger-menu-open {
			visibility: visible;
			opacity: 1;
			height: 100%;

			&::before {
				content: '';
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 200px;
				background-color: #ffffff;
				pointer-events: none;
			}
		}

		&__inner {
			overflow: hidden;
			position: relative;
			z-index: 100;
			padding: 20px 20px 42px;
			background-color: var(--color-bg);

			&::before {
				content: '';
				position: absolute;
				right: calc(50% - 195px);
				top: 0;
				z-index: -1;
				display: block;
				width: 598px;
				height: 100%;
				background: url(/assets/images/bg_single_slash_slim_long.svg) no-repeat right top / 598px auto;
			}
		}

		&__gnav {
			margin-bottom: 22px;
			background-color: #ffffff;

			a {
				position: relative;
				display: flex;
				align-items: center;
				min-height: 73px;
				padding: 20px 65px 20px 20px;
				border-bottom: solid 1px #e5e5e6;
				font-size: 1.8rem;
				font-weight: 500;

				&::after {
					@include m.iconfont-default;
					content: m.icon('arrow2-r');
					position: absolute;
					right: 20px;
					top: calc(50% - 14px);
					display: block;
					width: 28px;
					height: 28px;
					border: solid 1px var(--color-prim);
					font-size: 1rem;
					line-height: 26px;
					text-align: center;
				}

				// 別窓のアイコン
				&[target='_blank']::after {
					content: m.icon('blank');
				}

				// PDFのアイコン
				&[href$='.pdf']::after {
					content: m.icon('pdf');
				}
			}
		}

		&__child-btn {
			> a {
				background-color: #ffffff;
				transition: background-color .2s;

				&::before {
					content: '';
					position: absolute;
					right: 20px;
					top: calc(50% - 14px);
					display: block;
					width: 28px;
					height: 28px;
					border: solid 1px var(--color-prim);
				}

				&::after {
					content: m.icon('plus');
					right: 29px;
					top: calc(50% - 5px);
					width: 1rem;
					height: 1rem;
					border: none;
					line-height: 1;
					transition: transform .3s ease;
					transform: rotate(-90deg);
				}
			}

			&.is-toggle-open {
				padding-bottom: 30px;

				> a {
					background-color: #EEEEEE;
					border-bottom: none;

					&::after {
						content: m.icon('minus');
						transform: rotate(0deg);
					}
				}
			}
		}

		&__child-menu {
			overflow: hidden;
			padding: 0 20px;
			visibility: hidden;
			opacity: 0;
			height: 0;
			transition: opacity 0s, visibility 0s;

			#{$_root}__main-menu .is-toggle-open & {
				padding-top: 20px;
				visibility: visible;
				opacity: 1;
				height: auto;
				transition: opacity .4s, visibility .4s;
			}

			a {
				font-size: 1.6rem;
				padding-right: 40px;
				padding-left: 0;

				&::after {
					right: 0;
				}
			}

			&-group {
				a {
					padding-right: 54px;
					padding-left: 20px;

					&::after {
						right: 20px;
					}
				}
			}
		}

		&__lang-switch {
			font-family: Inter, var(--font-sans);

			> ul {
				display: flex;
				align-items: center;
				justify-content: center;

				> li {
					position: relative;
					font-size: 2.5rem;
					font-weight: 800;
					line-height: 1.2;

					&:first-child {
						margin-right: 16px;
						padding-right: 16px;

						&::before {
							content: '';
							position: absolute;
							top: 50%;
							right: 0;
							width: 1px;
							height: 32px;
							background-color: #D5D5D5;
							transform: translateY(-50%);
						}
					}

					a {
						color: #B5B5B5;
					}
				}
			}
		}
	}

	@include m.mq-pc {
		display: block;

		&__inner {
			flex: 1;
			display: flex;
			height: 100%;
		}

		&__gnav {
			flex: 1;
			display: flex;
			justify-content: flex-end;

			#{$_root}__main-menu > li > a,
			#{$_root}__sub-menu > li > a {
				position: relative;

				&::before {
					content: '';
					visibility: hidden;
					opacity: 0;
					display: block;
					position: absolute;
					bottom: 21px;
					width: calc(100% - 24px);
					height: 2px;
					background-color: var(--color-prim);
					transition: opacity .2s, visibility .2s;
				}

				&::after {
					@include m.iconfont-default;
					width: 1.2rem;
					height: 1.2rem;
					display: none;
					margin-left: 4px;
					font-size: 1.2rem;
				}

				&[target='_blank']::after {
					content: m.icon('blank');
					display: inline-block;
				}

				&[href$='.pdf']::after {
					content: m.icon('pdf');
					display: inline-block;
				}

				&.is-current::before,
				&:hover::before {
					visibility: visible;
					opacity: 1;
					transition: opacity .4s cubic-bezier(.65, 0, .35, 1), visibility .4s;
				}
			}

			#{$_root}__sub-menu > li > a {
				&::before {
					width: calc(100% - 10px);
				}
			}
		}

		&__child-btn {
			// メガメニュー反応補助エリア
			&.is-open {
				&::before {
					content: '';
					position: absolute;
					z-index: 2;
					transform: translate(68px, 55px) rotate(45deg);
					width: 0;
					height: 0;
					border-left: 30px solid transparent;
					border-right: 30px solid transparent;
					border-top: 30px solid rgba(255, 255, 255, 0);
				}

				&::after {
					content: '';
					position: absolute;
					z-index: 2;
					transform: translate(-39px, -25px) rotate(-45deg);
					width: 0;
					height: 0;
					border-left: 30px solid transparent;
					border-right: 30px solid transparent;
					border-top: 30px solid rgba(255, 255, 255, 0);
				}
			}
		}

		&__main-menu {
			display: flex;
			align-items: center;

			> li {
				height: 100%;
				font-weight: 500;

				> a {
					display: flex;
					align-items: center;
					height: 100%;
					padding: 10px 12px;
				}
			}
		}

		// mega menu
		&__child-menu {
			position: absolute;
			left: calc(50% - 600px);
			top: 80px;
			width: 1200px;
			background-color: #ffffff;
			padding: 50px 0 40px;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity .2s cubic-bezier(.33, 1, .68, 1), visibility .2s;

			.is-mega-menu-open & {
				transition: none;
			}

			#{$_root}__main-menu .is-open & {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}

			&::before {
				content: '';
				position: absolute;
				left: calc(50% - 50vw);
				@media (max-width: 1239px) {
					left: -20px;
				}
				top: 0;
				display: block;
				width: 100vw;
				min-width: 1240px;
				height: 100%;
				background-color: var(--color-bg);
			}

			&::after {
				content: '';
				position: absolute;
				right: calc(50% - 670px);
				top: 0;
				display: block;
				width: 500px;
				height: 100%;
				max-height: 514px;
				background: url(/assets/images/bg_single_slash.svg) no-repeat right top / 500px auto;
			}

			&-top {
				margin-bottom: 21px;
				font-size: 2.4rem;
				font-weight: bold;
				line-height: 1.25;

				> a {
					position: relative;
					display: inline-block;
					padding-right: 48px;

					&::after {
						@include m.iconfont-default;
						content: m.icon('arrow2-r');
						position: absolute;
						right: 0;
						top: 1px;
						display: block;
						width: 32px;
						height: 32px;
						border: solid 1px var(--color-prim);
						font-size: 1.2rem;
						line-height: 30px;
						text-align: center;
					}

					&:hover {
						text-decoration: underline;

						&::after {
							background-color: var(--color-prim);
							color: #ffffff;
						}
					}
				}
			}

			&-detail {
				position: relative;
				z-index: 1;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 50px 80px;
				background-color: #ffffff;

				// 事業内容
				&--service {
					display: block;
					padding: 44px 60px;

					#{$_root}__child-menu-item {
						a {
							padding: 0 0 26px;

							&::after {
								width: 24px;
								height: 24px;
								font-size: .9rem;
								line-height: 22px;
							}
						}
					}
				}
			}

			&-item {
				font-size: 1.6rem;
				font-weight: 500;
				line-height: var(--line-height-m);

				a {
					position: relative;
					display: block;
					height: 100%;
					padding: 0 48px 29px 0;
					border-bottom: solid 1px #d2d2d2;

					&::after {
						@include m.iconfont-default;
						content: m.icon('arrow2-r');
						position: absolute;
						right: 0;
						top: 1px;
						display: block;
						width: 32px;
						height: 32px;
						border: solid 1px var(--color-prim);
						font-size: 1.2rem;
						line-height: 30px;
						text-align: center;
					}

					&:hover {
						border-color: var(--color-prim);
						text-decoration: underline;

						&::after {
							background-color: var(--color-prim);
							color: #ffffff;
						}
					}
				}
			}

			&-3col {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				> li {
					width: 300px;

					&:nth-child(n+4) {
						margin-top: 40px;
					}

					&:nth-child(3n-1),
					&:nth-child(3n-2) {
						margin-right: 70px;
					}
				}
			}

			&-2col {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 670px;

				> li {
					width: 300px;

					&:nth-child(n+3) {
						margin-top: 40px;
					}
				}
			}

			&-1col {
				width: 300px;
			}

			// 事業内容 1段目
			&-service1 {
				text-align: center;

				#{$_root}__child-menu-item {
					a {
						&::after {
							position: static;
							display: inline-block;
							margin-left: 20px;
						}
					}
				}

				> #{$_root}__child-menu-item a {
					padding-bottom: 20px;
				}
			}

			// 事業内容 2段目
			&-service2 {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin-top: 20px;

				#{$_root}__child-menu-item {
					width: calc(50% - 20px);
					margin-top: 40px;
				}
			}

			// 事業内容 4階層ページのグループ
			&-group {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin-top: 35px;

				#{$_root}__child-menu-item {
					width: calc(50% - 2px);
				}
			}
		}

		&__sub-menu {
			display: flex;
			align-items: center;

			> li {
				height: 100%;
				font-size: 1.4rem;

				> a {
					display: flex;
					align-items: center;
					height: 100%;
					padding: 10px 5px;
				}
			}
		}

		&__lang-switch {
			width: 133px;
			padding: 0 40px 0 30px;
			font-family: Inter, var(--font-sans);

			> ul {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;

				> li {
					position: relative;
					font-size: 1.8rem;
					font-weight: 800;

					&:first-child {
						margin-right: 10px;
						padding-right: 10px;

						&::before {
							content: '';
							position: absolute;
							top: 50%;
							right: 0;
							width: 1px;
							height: 22px;
							background-color: #D5D5D5;
							transform: translateY(-50%);
						}
					}

					a {
						color: #B5B5B5;
						transition: color .2s;

						&:hover {
							color: var(--color-txt);
						}
					}
				}
			}
		}
	}
}
// stylelint-enable no-descending-specificity
