@use 'sass:math';
@use '../_mixin' as m;
/*----------
	localnav
----------*/

// stylelint-disable no-descending-specificity
.l-localnav {
	$this: &;
	overflow: hidden;
	position: relative;
	width: 100%;
	padding: 50px 0;
	background-color: var(--color-bg);

	&::before {
		content: '';
		position: absolute;
		left: calc(50% - 299px);
		top: 0;
		display: block;
		width: 598px;
		height: 525px;
		background: url(/assets/images/bg_single_slash_slim.svg) no-repeat center top / 598px auto;
	}

	&__inner {
		@include m.inner-content(1200px);
		position: relative;
		z-index: 1;
	}

	&__detail-links {
		padding: 30px 20px;
		background-color: #ffffff;
	}

	&__top {
		display: inline-block;
		position: relative;
		margin-bottom: 30px;
		font-size: 2rem;
		font-weight: bold;
		line-height: 1.5;

		> a {
			display: block;
			padding-right: 48px;
		}

		&-title {
			&::after {
				@include m.iconfont-default;
				content: m.icon('arrow2-r');
				position: absolute;
				right: 0;
				top: 2px;
				display: block;
				width: 28px;
				height: 28px;
				border: solid 1px var(--color-prim);
				font-size: 1rem;
				line-height: 26px;
				text-align: center;
			}
		}
	}

	&__sub-section {
		margin-top: 40px;
	}

	&__title {
		&::after {
			@include m.iconfont-default;
			content: m.icon('arrow2-r');
			position: absolute;
			right: 0;
			top: 1px;
			display: block;
			width: 28px;
			height: 28px;
			border: solid 1px var(--color-prim);
			font-size: 1rem;
			line-height: 26px;
			text-align: center;
		}
	}

	&__item {
		margin-top: 22px;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-m);

		> a {
			position: relative;
			display: block;
			padding: 0 40px 23px 0;
			border-bottom: solid 1px #d2d2d2;
		}

		&--1col-center {
			margin-top: 0;
			text-align: center;

			> a {
				padding-right: 0;
				padding-bottom: 12px;
			}

			#{$this}__title {
				display: inline-block;

				&::after {
					position: static;
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-left: 10px;
					font-size: .7rem;
					line-height: 18px;
					transform: translateY(-2px);
				}
			}
		}

		&--2col-center {
			> a {
				padding-right: 54px;
				padding-left: 20px;
			}

			#{$this}__title {
				display: inline-block;

				&::after {
					right: 20px;
				}
			}
		}
	}

	@include m.mq-pc {
		min-width: 1240px;

		&::before {
			left: calc(50% + 100px);
			top: 0;
			width: 50%;
			height: 100%;
			background: url(/assets/images/bg_single_slash.svg) no-repeat left top / contain;
		}

		&__detail-links {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 43px 60px 44px;
		}

		&__top {
			font-size: 2.4rem;
			line-height: 1.25;

			> a {
				display: block;
				padding-right: 48px;

				&:hover {
					#{$this}__top-title {
						text-decoration: underline;

						&::after {
							background-color: var(--color-prim);
							color: #ffffff;
						}
					}
				}
			}

			&-title {
				&::after {
					top: 1px;
					width: 32px;
					height: 32px;
					font-size: 1.2rem;
					line-height: 30px;
				}
			}
		}

		&__sub-section {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			margin-top: 60px;

			> #{$this}__item:nth-child(-n+2) {
				margin-top: 0;
			}
		}

		&__title {
			&::after {
				top: 1px;
				width: 24px;
				height: 24px;
				font-size: .9rem;
				line-height: 22px;
			}
		}

		&__item {
			width: calc(50% - 20px);
			margin-top: 40px;

			> a {
				padding: 0 48px 26px 0;

				&:hover {
					border-color: var(--color-prim);

					#{$this}__title {
						text-decoration: underline;

						&::after {
							background-color: var(--color-prim);
							color: #ffffff;
						}
					}
				}
			}

			&--1col-center {
				width: 100%;
				margin-top: 0;
				font-size: 1.6rem;

				> a {
					padding-right: 0;
					padding-bottom: 17px;
				}

				#{$this}__title {
					display: inline-block;

					&::after {
						width: 24px;
						height: 24px;
						margin-left: 20px;
						font-size: .9rem;
						line-height: 22px;
						transform: translateY(-2px);
					}
				}
			}

			&--2col-center {
				width: calc(50% - 2px);
				margin-top: 32px;
				text-align: center;

				> a {
					padding-right: 0;
					padding-left: 0;
					padding-bottom: 26px;
				}

				#{$this}__title {
					display: inline-block;

					&::after {
						display: inline-block;
						position: static;
						margin-left: 20px;
					}
				}
			}
		}
	}
}
// stylelint-enable no-descending-specificity