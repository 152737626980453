@use 'sass:math';
@use '../_mixin' as m;
/*----------
	vars
----------*/

:root {
	/* color */
	--color-prim: #222222;
	--color-seco1: #1E2380;
	--color-seco2: #00873B;
	--color-txt: #222222;
	--color-txt-caption: #7E7E7E;
	--color-txt-link: #222222;
	--color-txt-hover: #222222;
	--color-border: #E2E2E2;
	--color-border2: #E9E9E9;
	--color-bg: #F5F5F5;
	--color-caution: #DE5252;
	--color-red: #E6002D;

	--color-prim-rgb: #{m.hex2rgb(#222222)};
	--color-seco1-rgb: #{m.hex2rgb(#1E2380)};
	--color-seco2-rgb: #{m.hex2rgb(#00873B)};
	--color-txt-rgb: #{m.hex2rgb(#222222)};
	--color-txt-caption-rgb: #{m.hex2rgb(#7E7E7E)};
	--color-txt-link-rgb: #{m.hex2rgb(#222222)};
	--color-txt-hover-rgb: #{m.hex2rgb(#222222)};
	--color-border-rgb: #{m.hex2rgb(#E2E2E2)};
	--color-border-rgb: #{m.hex2rgb(#E9E9E9)};
	--color-bg-rgb: #{m.hex2rgb(#F5F5F5)};
	--color-caution-rgb: #{m.hex2rgb(#DE5252)};
	--color-red-rgb: #{m.hex2rgb(#E6002D)};


	/* font family */
	--font-sans: 'Noto Sans JP', 'ヒラギノ角ゴシック', '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', Hiragino Sans, 'メイリオ', Meiryo, sans-serif;
	--font-serif: 'Noto Serif JP', 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', '游明朝', 'Yu Mincho', '游明朝体', 'YuMincho', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;

	/* line height */
	--line-height-s: 1.4;
	--line-height-m: 1.6;
	--line-height-l: 1.8;

	/* icons */
	@each $name, $glyph in m.$icons {
		--icon-#{$name}: '#{$glyph}';
	}
}

